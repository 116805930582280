@charset "utf-8";

// Define defaults for each variable.

$base-font-family:
  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;

$brand-color: #2a7ae2 !default;
$brand-color-light: lighten($brand-color, 40%) !default;
$brand-color-dark: darken($brand-color, 25%) !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-mobile: 640px !default;
$on-tablet: 768px !default;
$on-laptop: 1024px !default;
$on-desktop: 1024px !default;

// Use media queries like this:
// @include media-query($on-mobile) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import 'minima/base', 'minima/layout', 'minima/syntax-highlighting',
  'minima/index', 'minima/place_page';
