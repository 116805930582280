a svg {
  width: 20px;
}

.price-rating {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
}
